import React from 'react';
import { Label, TextInput } from 'shared-library';
import { Column, Row } from '../Layout';
import { AmountToPay } from '../AmountToPay';
import { ReferenceIdTextInput } from './ReferenceIdTextInput';
import { StyledSelectInput } from './PaymentTextInputs';

const externalCreditPaymentTypes = [
  {
    value: 'physical',
    label: 'Physical Terminal',
  },
  {
    value: 'virtual',
    label: 'Virtual Terminal',
  },
];
const cardTypes = [
  { value: 'Visa', label: 'Visa' },
  { value: 'Mastercard', label: 'Mastercard' },
  { value: 'Discover', label: 'Discover' },
  { value: 'American Express', label: 'American Express' },
  { value: 'Other', label: 'Other' },
];

const ExternalCreditColumns = ({ transaction, setTransaction }) => {
  const limit = (val, max) => {
    if (val.length === 1 && val[0] > max[0]) {
      val = `0${val}`;
    }
    if (val.length === 2) {
      if (Number(val) === 0) {
        val = '01';
      } else if (val > max) {
        val = max;
      }
    }
    return val;
  };

  const cardExpiry = (val) => {
    const month = limit(val.substring(0, 2), '12');
    const year = val.substring(2, 4);
    return month + (year.length ? `/${year}` : '');
  };

  return (
    <>
      <Row>
        <Column>
          <Label>Payment Method</Label>
          <StyledSelectInput
            options={externalCreditPaymentTypes}
            defaultValue={externalCreditPaymentTypes.filter(
              (s) => s.value === transaction.paymentMethod,
            )}
            onChange={(option) => setTransaction({ ...transaction, paymentMethod: option.value })}
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <Label>Card Type</Label>
          <StyledSelectInput
            options={cardTypes}
            defaultValue={cardTypes.filter((s) => s.value === transaction.cardType)}
            onChange={(option) => setTransaction({ ...transaction, cardType: option.value })}
          />
        </Column>
        <Column>
          <TextInput
            label="Last 4 Digits"
            pattern="\d*"
            maxLength={4}
            onChange={(value) =>
              setTransaction({
                ...transaction,
                cardLastFour: value,
              })
            }
            placeholder="1234"
            value={transaction.cardLastFour}
          />
        </Column>
        <Column>
          <TextInput
            id="transaction.expirationDate"
            label="Expiration Date"
            placeholder="MM/YY"
            format={cardExpiry}
            onChange={(value) =>
              setTransaction({
                ...transaction,
                expirationDate: value,
              })
            }
            value={transaction.expirationDate}
          />
        </Column>
      </Row>
      <Row>
        <AmountToPay transaction={transaction} setTransaction={setTransaction} />
        <ReferenceIdTextInput setTransaction={setTransaction} transaction={transaction} />
      </Row>
    </>
  );
};

export default ExternalCreditColumns;
